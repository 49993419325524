
























import { promiseHelper } from '@/helpers/promise-helper'
import { Observer } from 'mobx-vue'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { MarketItemViewModel } from '../viewmodels/market-viewmodel'

@Observer
@Component({})
export default class MarketSaleDialog extends Vue {
  @Prop({ required: true, default: null }) vm!: MarketItemViewModel

  dialog = false
  resolve?: (any) => void

  vestingScheduleHeaders = [
    { text: '#', value: 'index', sortable: false },
    { text: 'Date', value: 'date', sortable: false },
    { text: 'Vesting Amount', value: 'percentage', sortable: false },
  ]
  vestingSchedules = [
    {
      index: 0,
      date: 1634844040,
      percentage: '20%',
    },
    {
      index: 1,
      date: 1634844040,
      percentage: '20%',
    },
    {
      index: 2,
      date: 1634844040,
      percentage: '20%',
    },
    {
      index: 3,
      date: 1634844040,
      percentage: '20%',
    },
  ]

  async open() {
    this.dialog = true
    await promiseHelper.delay(100)
    return new Promise((r) => (this.resolve = r))
  }
  async cancel() {
    this.resolve && this.resolve(null)
    this.dialog = false
    await promiseHelper.delay(100)
  }
}
